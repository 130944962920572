import {
    ADD_API_TOKEN_STEP,
    API_TOKEN_FETCHED,
    API_TOKEN_REMOVE_DONE,
    API_TOKEN_REMOVED,
    API_TOKEN_REMOVING, API_TOKEN_REQUEST_FAILED, API_TOKEN_REQUESTED, API_TOKEN_REQUESTING,
    API_TOKENS_LIST_FETCHED,
    FETCHING_API_TOKEN,
    FETCHING_API_TOKENS_LIST,
    SWITCH_ADD_API_TOKEN_DIALOG,
    SWITCH_DELETE_API_TOKEN_DIALOG,
} from "../constants/ActionTypes";


const INIT_STATE = {
    loadingApiTokens: false,
    apiTokens: [],
    deletingApiToken: false,
    tokenID: -1,
    addingApiToken: false,
    deleteDialogOpened: false,
    addApiTokenDialogOpened: false,
    redraw: false,
    totalApiTokens: 0,
    apiTokenAdded: false,
    loadingApiToken: false,
    apiToken: {},
    addTokenStep: 1,
    addTokenVerifyDetails: {},
}


export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case API_TOKEN_REQUESTING: {
            return {...state, addingApiToken: true};
        }

        case API_TOKEN_REQUESTED: {
            let apiToken = {};
            let apiTokens = state.apiTokens.slice();
            if(action.payload.addTokenStep && action.payload.addTokenStep === 3) {
                apiToken = action.payload.apiToken;
                apiTokens.push(apiToken);
            }
            return {...state, addingApiToken: false, addTokenStep: action.payload.addTokenStep ?? state.addTokenStep,
                addTokenVerifyDetails: action.payload.addTokenVerifyDetails ?? state.addTokenVerifyDetails,
                apiToken : apiToken, apiTokens,
            };
        }

        case API_TOKEN_REQUEST_FAILED: {
            return {...state, addingApiToken: false, addTokenStep: action.payload.addTokenStep ?? state.addTokenStep,};
        }

        case ADD_API_TOKEN_STEP: {
            return {...state, addTokenStep: action.payload.step};
        }

        case FETCHING_API_TOKENS_LIST: {
            return {...state, loadingApiTokens: true, redraw: false};
        }
        case API_TOKENS_LIST_FETCHED: {
            return {...state, loadingApiTokens: false, redraw: false, apiTokens: action.payload.apiTokens ?? [], totalApiTokens: action.payload.totalApiTokens};
        }

        case FETCHING_API_TOKEN: {
            return {...state, loadingApiToken: true, redraw: false};
        }
        case API_TOKEN_FETCHED: {
            return {...state, loadingApiToken: false, redraw: false, apiToken: action.payload.apiToken ?? {}};
        }

        case API_TOKEN_REMOVING: {
            return {...state, deletingApiToken: true, tokenID: action.payload.tokenID};
        }
        case API_TOKEN_REMOVE_DONE: {
            return {...state, deletingApiToken: false};
        }
        case API_TOKEN_REMOVED: {
            let newApiTokenAddresses = state.apiTokens.filter(apiToken => apiToken.id !== state.tokenID);

            return {...state, deletingApiToken: false, apiTokens: newApiTokenAddresses};
        }

        case SWITCH_DELETE_API_TOKEN_DIALOG: {
            return {...state, deleteDialogOpened: action.payload.state, selectedOrder: {}, deletableID: action.payload.deletableID,};
        }
        case SWITCH_ADD_API_TOKEN_DIALOG: {
            console.log('reducer:', action.payload)
            return {...state, addApiTokenDialogOpened: action.payload.state };

        }
        default:
            return state;
        
    }

}