import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';

import IconButton from "@material-ui/core/IconButton";
import {MOBILE_MAX_WIDTH} from "../../panel/routes/data/constants";
import {MINI_DRAWER} from "../../constants/ActionTypes";
import {setDrawerType, toggleCollapsedNav, updateWindowWidth} from "../../actions";

class OurSideNav extends React.PureComponent {

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

    componentDidMount() {
      const{width} = this.props;
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
    // if (width < 1200) {
    //     this.props.setDrawerType(MINI_DRAWER);
    //   }
  };
    setMiniDrawer = () => {
        const {drawerType} = this.props;
        if (drawerType === FIXED_DRAWER){
            this.props.setDrawerType(MINI_DRAWER);
        }
        else
            this.props.setDrawerType(MINI_DRAWER);

    };


  render() {
    const {darkTheme, navCollapsed, drawerType, width, navigationStyle} = this.props;
    // // if (width < 1200 && width >= 580) {
    // //     this.props.setDrawerType(MINI_DRAWER)
    // // }
    //
    // let drawerStyle = drawerType.includes(MINI_DRAWER) ? 'd-xl-flex' : drawerType.includes(MINI_DRAWER) ? '' : 'd-xl-flex';
    // let type = 'permanent';
    //   if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 580)) {
    //       type = 'temporary';
    //   }
    //   // if (width < 1200) {
    //   //    type = 'permanent';
    //   //   }
    //
    // if (navigationStyle === HORIZONTAL_NAVIGATION) {
    //   drawerStyle = '';
    //   type = 'temporary';
    // }
      let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
      let type = 'permanent';
      if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
          type = 'temporary';
      }

      if (navigationStyle === HORIZONTAL_NAVIGATION) {
          drawerStyle = '';
          type = 'temporary';
      }
    return (
      <div className={`app-sidebar  d-none ${drawerStyle}`}>

          <Drawer className="app-sidebar-content"
                  variant={type}
                  open={type.includes('temporary') ? navCollapsed : true}

                  onClose={this.onToggleCollapsedNav}
                  classes={{
                      paper: 'side-nav',
                  }}
          >

              <div className="header-logo">
                  <img alt="alt" src={require("../../assets/images/mars/Changiny.png")}/>
              </div>
              <SidenavContent/>

          </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
    const {navCollapsed, drawerType, width, navigationStyle, darkTheme} = settings;
  return {navCollapsed, drawerType, width, navigationStyle, darkTheme}
};
export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, setDrawerType, updateWindowWidth})(OurSideNav));



